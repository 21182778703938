import FeeSchedule from "store/models/FeeSchedule";

import StoreActions from "store/actions";

const storeActions = new StoreActions(FeeSchedule);
export default class FeeScheduleAction {
  async getAllFeeSchedules(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "vbsFeeSchedules",
        relativeUrl: "/fee-schedule/get-all",
        ...variables,
      },
      desciption.length ? desciption : "to load fee schedule list."
    );
  }

  async create(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "createVbsFeeSchedule",
        relativeUrl: "/fee-schedule/create-fee-schedule",
        queries: queries || null,
        collectionQueries: collectionQueries || [],
      },
      "To create fee schedule account.",
      { ...variables }
    );
  }

  async get(params) {
    const { limitData, variables } = params;

    return await storeActions.query(
      {
        queryName: "getVbsFeeSchedule",
        relativeUrl: "/fee-schedule/get-fee-schedule",
        limitData: limitData || null,
      },
      "To fetch fee schedule.",
      { ...variables }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;

    return await storeActions.mutation(
      {
        relativeUrl: "/fee-schedule/update-fee-schedule",
        queryName: "updateVbsFeeSchedule",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
      },
      "To update fee schedule.",
      {
        ...variables,
      }
    );
  }

  async updateStatus(variables, isActive){
    const { feeSchedId, limitData = [], shouldNotReturnId = false} = variables;
    return await storeActions.mutation(
      {
        relativeUrl: "/fee-schedule/update-fee-schedule",
        queryName: isActive ? "deactivateVbsFeeSchedule" : "activateVbsFeeSchedule",
        limitData,
        shouldNotReturnId,
      },
      "To update status of fee schedule.",
      {
        id: feeSchedId,
      }
    ); 
  }  
}
