import Modifier from "store/models/Modifier";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Modifier);
export default class ModifierAction {
    
  async getAll(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "modifiers",
        relativeUrl: "/modifier/get-all",
        ...variables,
      },
      desciption.length ? desciption : "to load modifier list."
    );
  }

  async create(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "createModifier",
        relativeUrl: "/modifier/create-modifier",
        queries: queries || null,
        collectionQueries: collectionQueries || [],
      },
      "To create fee schedule account.",
      { ...variables }
    );
  }
}