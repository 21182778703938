import Actions from "modules/actions";
import PayorAccount from "store/actions/payor-account";
import InsuranceCompany from "store/actions/insuranceCompany";
import FeeSchedule from "store/actions/feeSchedule";
import Modifier from "store/actions/modifier";

const payorAccountActions = new PayorAccount();
const insuranceCompanyActions = new InsuranceCompany();
const feeScheduleAction = new FeeSchedule();
const modifierAction = new Modifier();

export default class FeeScheduleAction extends Actions {
  async searchPayorAccount(params) {
    return await payorAccountActions.searchPayorAccount(params);
  }
  async getInsuranceCompanyList(variables) {
    return insuranceCompanyActions.getInsuranceCompanyList(variables);
  }
  async getAllFeeSchedules(variables) {
    return feeScheduleAction.getAllFeeSchedules(variables)
  }

  async getFeeSchedule(variables){
    return feeScheduleAction.get(variables);
  }

  async createFeeSchedule(variables) {
    return feeScheduleAction.create(variables);
  }
  
  async updateFeeSchedule(variables){
    return feeScheduleAction.update(variables);
  }

  async updateFeeScheduleStatus(variables, isActive) {
    return feeScheduleAction.updateStatus(variables, isActive);
  }

  async getAllModifier(variables) {
    return modifierAction.getAll(variables);
  }

  async createModifier(variables) {
    return modifierAction.create(variables);
  }
}

